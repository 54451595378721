// const determineInitialStep = (status) => {
//   switch (status) {
//     case "PROPERTY_DETAILS":
//       return 0;
//     case "ELIGIBILITY_DETAILS":
//       return 1;
//     case "ELIGIBILITY_CONFIRMED":
//       return 1;
//     case "ELIGIBILITY_DETAILS_REJECTED":
//       return 1;
//     case "AADHAAR_VERIFICATION":
//       return 2;
//     case "INITIAL_LOGIN_FEE_PENDING":
//       return 2;
//     case "APPLICANT_DETAILS":
//       return 3;
//     case "PERSONAL_DETAILS":
//       return 3;
//     case "KYC_DETAILS":
//       return 3;
//     case "PHOTO_VERIFICATION":
//       return 3;
//     case "AIS_DETAILS":
//       return 4;
//     case "QALIFICATION_BUSINESS_DETAILS":
//       return 4;
//     case "EMPLOYMENT_DETAILS":
//       return 4;
//     case "ITR_DETAILS":
//       return 4;
//     case "GST_RETURN_DETAILS":
//       return 4;
//     case "REFERENCE_DETAILS":
//       return 4;
//     case "ACCOUNT_AGGREGATOR_DETAILS":
//       return 5;
//     case "BANK_STATEMENT_DETAILS":
//       return 5;
//     case "INCOME_ASSESMENT_DETAILS":
//       return 5;
//     case "LOAN_OFFER_CONFIRMED":
//       return 6;
//     case "SANCTION_LETTER_GENERATED":
//       return 7;
//     case "VKYC_DETAILS":
//       return 8;
//     case "VKYC_DETAILS_INPROGRESS":
//       localStorage.setItem("isBusinessloanVkycRedirected", "true");
//       return 9;
//     case "BANK_ACCOUNT_DETAILS":
//       return 10;
//     case "MANDATE_CHECK":
//       return 11;
//     case "MANDATE_CHECK_INPROGRESS":
//       localStorage.setItem("isBusinessloanmandateRedirected", "true");
//       return 12;
//     case "LOAN_AGREEMENT_DETAILS":
//       return 13;
//     case "LOAN_AGREEMENT_DETAILS_INPROGRESS":
//       localStorage.setItem("isBusinessloanEmsignerRedirected", "true");
//       return 14;
//     default:
//       return 0;
//   }
// };

import React, { useEffect, useState } from "react";
import Wizard from "./Wizard";
import PersonalAndBusinessDetailsForm from "./W3/PersonalAndBusinessDetailsForm";
import EligibilityCheckForm from "./EligibilityCheckForm";
import FirstFeePayment from "./FirstFeePayment";
import BasicPropertyDetails from "./BasicPropertyDetails";
import ApplicantSummaryDetails from "./ApplicantsSummary";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EligibilityTenureForm from "./EligibilityTenureForm";
import AddApplicantProfessionalDetails from "./AddApplicantProfessionalDetails";
import UpdatePrimaryApplicantSalaried from "./UpdatePrimaryApplicantSalaried";
import AddDeceasedApplicant from "./AddDeceasedApplicant";
import PersonalAndBusinessDetailsNoPANForm from "./PersonalAndBusinessDetailsNoPANForm";
import AddApplicantProfessionalNoPANDetails from "./AddApplicantProfessionalNoPANDetails";
import UpdatePrimaryApplicantSalariedNoPAN from "./UpdatePrimaryApplicantSalariedNoPAN";
import KYCAdharVerification from "./KYCAdharVerification";
import KYCDetailsForm from "./KYCDetailsForm";
import AdharOTPVerification from "./AdharOTPVerification";
import PhotoVerification from "./PhotoVerification";
import Refernces from "./Refences";
import AddDeceasedNoPANApplicant from "./AddDeceasedNoPANApplicant";
import QualificationandBusinessDetails from "./QualificationandBusinessDetails";
import ITrDetails from "./ITR";
import GstrDetails from "./Gstr";
import IncomeAssessmentDetails from "./BusinessIncomeAssessment";
import BankStatement from "./BankStatement";
import QualificationIncomeRetriedForm from "./QualificationIncomeRetriedForm";
import EmployementDetails from "./EmployementDetails";
import AdditionalEmployementDetails from "./AdditionalEmployementDetails";
const LoanAgainstProperty = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [applicationStatus, setApplicationStatus] =
    useState("PROPERTY_DETAILS");
  const [applicantStatus, setApplicantStatus] = useState("");
  const [currentComponent, setCurrentComponent] = useState(null); // null initially
  const [activeStep, setActiveStep] = useState(0); // Default step to 0

  // Get application status from location state
  useEffect(() => {
    if (state?.lapLoanApplicationStatus) {
      setApplicationStatus(state.lapLoanApplicationStatus);
    }
  }, [state]);

  const changeStatus = (status) => {
    setApplicationStatus(status);
  };

  const changeApplicantStatus = (applicantStatus) => {
    setApplicantStatus(applicantStatus);
  };

  // Only update the component and step if applicationStatus or applicantStatus changes
  useEffect(() => {
    if (applicationStatus) {
      const { component, step } = renderPage();
      if (currentComponent !== component) {
        setActiveStep(step); // Update step
        setCurrentComponent(component); // Update component
      }
    }
  }, [applicationStatus, applicantStatus]);

  const renderPage = () => {
    let step = 0; // Default step

    if (applicationStatus === "APPLICANT_DETAILS") {
      step = 3; // Set step for APPLICANT_DETAILS
      if (applicantStatus) {
        switch (applicantStatus) {
          case "PersonalAndBusinessDetailsForm":
            return {
              component: (
                <PersonalAndBusinessDetailsForm
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "PersonalAndProfessionalDetailsForm":
            return {
              component: (
                <AddApplicantProfessionalDetails
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "PersonalDetailsForm":
            return {
              component: (
                <UpdatePrimaryApplicantSalaried
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "DeceasedDetailsForm":
            return {
              component: (
                <AddDeceasedApplicant
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "DeceasedDetailsNoPANForm":
            return {
              component: (
                <AddDeceasedNoPANApplicant
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "PersonalAndProfessionalDetailsNoPANForm":
            return {
              component: (
                <AddApplicantProfessionalNoPANDetails
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "PersonalAndBusinessDetailsNoPANForm":
            return {
              component: (
                <PersonalAndBusinessDetailsNoPANForm
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "PersonalDetailsNoPANForm":
            return {
              component: (
                <UpdatePrimaryApplicantSalariedNoPAN
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "KYCAdharVerification":
            return {
              component: (
                <KYCAdharVerification
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "AdharOTPVerification":
            return {
              component: (
                <AdharOTPVerification
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "KYCDetailsForm":
            return {
              component: (
                <KYCDetailsForm
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "QualificationAndIncomeDetails":
            return {
              component: (
                <QualificationIncomeRetriedForm
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "PhotoVerification":
            return {
              component: (
                <PhotoVerification
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "ApplicantAndBusinessDetails":
            return {
              component: (
                <QualificationandBusinessDetails
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "Reference":
            return {
              component: (
                <Refernces
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
          case "ITR":
              return {
                component: (
                  <ITrDetails
                    changeStatus={changeStatus}
                    changeApplicantStatus={changeApplicantStatus}
                  />
                ),
                step,
              };
          case "Gstr":
                return {
                  component: (
                    <GstrDetails
                      changeStatus={changeStatus}
                      changeApplicantStatus={changeApplicantStatus}
                    />
                  ),
                  step,
                };
          case "BusinessIncomeAssessment":
                return {
                  component: (
                    <IncomeAssessmentDetails
                      changeStatus={changeStatus}
                      changeApplicantStatus={changeApplicantStatus}
                    />
                  ),
                  step,
                };
          case "BankStatement":
                return {
                  component: (
                    <BankStatement
                      changeStatus={changeStatus}
                      changeApplicantStatus={changeApplicantStatus}
                    />
                  ),
                  step,
                };
          case "EmployementDetails":
                  return {
                    component: (
                      <EmployementDetails
                        changeStatus={changeStatus}
                        changeApplicantStatus={changeApplicantStatus}
                      />
                    ),
                    step,
                  };
          case "AdditionalEmployementDetails":
                    return {
                      component: (
                        <AdditionalEmployementDetails
                          changeStatus={changeStatus}
                          changeApplicantStatus={changeApplicantStatus}
                        />
                      ),
                      step,
                    };
          default:
            return {
              component: (
                <ApplicantSummaryDetails
                  changeStatus={changeStatus}
                  changeApplicantStatus={changeApplicantStatus}
                />
              ),
              step,
            };
        }
      } else {
        return {
          component: (
            <ApplicantSummaryDetails
              changeStatus={changeStatus}
              changeApplicantStatus={changeApplicantStatus}
            />
          ),
          step,
        };
      }
    } else {
      switch (applicationStatus) {
        case "PROPERTY_BASIC_DETAILS":
          step = 0;
          return {
            component: <BasicPropertyDetails changeStatus={changeStatus} />,
            step,
          };
        case "ELIGIBILITY_DETAILS":
          step = 1;
          return {
            component: <EligibilityCheckForm changeStatus={changeStatus} />,
            step,
          };
        case "ELIGIBILITY_CONFIRMED":
          step = 1;
          return {
            component: <EligibilityTenureForm changeStatus={changeStatus} />,
            step,
          };
        case "INITIAL_LOGIN_FEE_PENDING":
          step = 2;
          return {
            component: <FirstFeePayment changeStatus={changeStatus} />,
            step,
          };
        default:
          step = 0;
          return {
            component: <BasicPropertyDetails changeStatus={changeStatus} />,
            step,
          };
      }
    }
  };

  return (
    <div className="row m-sm-0 m-0">
      <div className="col-12 col-sm-12 col-md-9 mx-auto">
        <div className="mx-2 plFormBox">
          <div className="formWizard p-2">
            <h5 className="text-center mb-3">Loan Against Property</h5>
            <Wizard activeStep={activeStep} />{" "}
          </div>
          <div className="personalForm"> 
            {currentComponent} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanAgainstProperty;


import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, InputLabel, Select, MenuItem, Dialog } from "@mui/material";
import "./personalLoan.css";
import {
  FaIdBadge,
  FaCheck,
  FaExclamationTriangle,
  FaExclamationCircle,
} from "react-icons/fa";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { EmployeerForm } from "./employeerForm";
import UploadDocument from "./UploadDocument";
import { handleSignOut } from "./SessionExpiredApi";
import { accountAggregatorAPI, propertyLoanEmailOtpValidation, propertyLoanEmailVerification, propertyLoanFetchDataAPI,propertyLoanBackPageAPI, propertyLoangetEmployeeHistory, propertyLoanstoreEmployeeHistoryAPI } from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
const AdditionalEmployementDetails = ({changeApplicantStatus }) => {

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [formData, setFormData] = useState({
    qualification: "",
    workExperience: "",
    currentEmployer: "",
    monthlyIncome:"",
    monthlyObligation:"",
    employerType: "",
    employerCategory: "",
    workingSince: "",
    accNo: '',
    employerWebsite: '',
    designation: '',
    salaryAccountBank: "",
    officialEmailID: "",
    officialEmail: "",
    document: "",
    previousEmployer: [],
    previousEmployerType: [],
    workedFrom: [],
    workedTill: [],
    previousSalaryAccountBank: [],
    previousOfficialEmailID: [],
    
  });
  const [isPopupOpen1, setPopupOpen1] = useState(false);
  const [prevCount, setPreviousCount] = React.useState(0);
  const [selectedEmployeerNames, setSelectedEmployeerNames] = React.useState([]);
  const fileInputRefID = useRef(null);
  const fileInputRefID2 = useRef(null);
  const [isIDUploaded, setIsIDUploaded] = useState(false);
  const [isIDUploaded2, setIsIDUploaded2] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [showBankStatement, setShowBankStatement] = useState(false);
  const [showAccountAggregator, setShowAccountAggregator] = useState(false);
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const [repeatCount, setRepeatCount] = React.useState([]);
  const [errorDisplayed, setErrorDisplayed] = useState(false);

  const errorDisplayedRef = useRef(false); 
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; 

    setErrorDisplayed(true); 
    errorDisplayedRef.current = true; 

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const   handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const getTotalDaysInLast12Months = (months) => {
    let currentDate = new Date();
    let totalDays = 0;

    for (let i = 0; i < months; i++) {
      currentDate.setMonth(currentDate.getMonth() - 1);
      let daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      totalDays += daysInMonth;
    }

    return totalDays;
  };

  const handleFileChangeID = (e) => {
    const selectedFile = e.target.files[0];
    let temp = selectedEmployer;
    let tempArray = selectedEmployer.document;
    tempArray.push(selectedFile);
    setSelectedEmployer({ ...selectedEmployer, document: tempArray });
    if (selectedFile) {
      setFileName(selectedFile.name);

      setFormData({
        ...formData,
        document: selectedFile,
      });
      setIsIDUploaded(true);
    } else {
      setFileName("");
      setIsIDUploaded(false);
    }
  };
  const handleFileChangeID2 = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFileName2(selectedFile.name);
      setIsIDUploaded2(true);
    } else {
      setFileName2("");
      setIsIDUploaded2(false);
    }
  };

  const handleUploadButtonClickID = () => {
    fileInputRefID.current.click();
  };

  const handleUploadButtonClickID2 = () => {
    fileInputRefID2.current.click();
  };
  const [selectedEmployer, setSelectedEmployer] = React.useState({});
  const [selectedPreviuosEmployer, setSelectedPreviuosEmployer] =
    React.useState({});

  const [res, setRes] = React.useState();
  useEffect(() => {

    const fetchAddressData = async () => {
      try {
        const res = await propertyLoangetEmployeeHistory();
        if (res.data.employerDetails && res.data.employerDetails.length > 0) {
          for (let index = 0; index < res.data.employerDetails.length; index++) {
            setRepeatCount((prevRepeatCount) => [
              ...prevRepeatCount,
              "dummy",
            ]);

          }
        }
        setRes(res.data);
      } catch (error) {
        if (error.response && error?.response?.status === 401) {
          await handleSignOut();
          toast.error("Your Session has expired.You will be redirected to Login Page.")
          navigate('/')
        } else if (error.response && error?.response?.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        } else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error fetching address data:", error);
      }
    };

    fetchAddressData();

    if (res) {
      let obj = {
        qualification: res.qualification,
        workExperience: res.workExperience,
        monthlyIncome:res.monthlyIncome,
        monthlyObligation:res.monthlyObligation,
        currentEmployer: "",
        employerType: "",
        workingSince: "",
        salaryAccountBank: "",
        officialEmailID: "",
        previousEmployer: [],
        previousEmployerType: [],
        workedFrom: [],
        workedTill: [],
        previousSalaryAccountBank: [],
        previousOfficialEmailID: [],
      };
      setFormData(obj);
      console.log(res);

    }
  }, []);


  const [previousEmployeerDocuments, setpreviousEmployeerDocuments] =
    React.useState([]);

  useEffect(() => {
    if (!formData.currentEmployer) {
      if (res && res.employerDetails) {
        let emps = res.employerDetails;

        let currentEmployeerName = "";
        let monthlyIncome = "";
        let monthlyObligation = "";
        let workingSince = "";
        let employerCategory = "";
        let previousEmployer = [];
        let previousEmployeerType = [];
        let previousEmployeerFromDate = [];
        let previousEmployeerTillDate = [];
        let officialEmailID = "";
        let prevOfficialEmailId = [];
        let accNo = '';
        let designation = '';
        let employerWebsite = '';
        let officialEmail = '';
        if (emps) {
          for (let index = 0; index < emps.length; index++) {
            const element = emps[index];
            if (element.employerType == "Current Employer") {
              setSelectedEmployer(element);
              currentEmployeerName = element.companyName;
              employerCategory = element.employerCategory;
              workingSince = element.workfrom;
              monthlyIncome = element.monthlyIncome;
              monthlyObligation = element.monthlyObligation;
              officialEmailID = element.officialEmail;
              accNo = element.accNo;
              employerWebsite = element.employerWebsite;
              designation = element.designation;
              officialEmail = element.officialEmail;

              setCurrentAddress(element.address);
              if (element.address) {
                if (element.address.city || element.address.state || element.address.line1 || element.address.line2) {
                  setAddressAdded(true);
                }
              }
              if (workingSince) {
                let currentDays = getdaysDifference(
                  new Date(workingSince),
                  new Date()
                );
                setWorkedSinceDays(currentDays);
                if (currentDays < days) {
                  // setRepeatCount( [ ...repeatCount, "dummy" ] );
                  if (
                    repeatCount.length < 2
                  ) {
                    setRepeatCount([...repeatCount, "dummy"]);
                    // setRepeatCount( ( prevRepeatCount ) => [
                    //   ...prevRepeatCount,
                    //   "dummy",
                    // ] );
                    setPreviousCount(prevCount + 1);
                    setWorkedFromCounter(workedFromCounter + 1);
                    setWorkedTillCounter(workedTillCounter + 1);
                  }
                }
              }
            }
            if (element.employerType == "Previous Employer 1") {
              const newElement = JSON.parse(JSON.stringify(element)); // Deep copy
              setPreviousEmployerList((prevList) => [...prevList, newElement]);
              let tempPreviousEmployer = element.companyName;
              previousEmployer = [...previousEmployer, tempPreviousEmployer];
              setPrev1Address(element.address);
              if (element.address) {
                if (element.address.city || element.address.state || element.address.line1 || element.address.line2) {
                  setPrev1AddressAdded(true);
                }
              }
              setPrev1Info({
                accNo: element.accNo,
                designation: element.designation,
                employerWebsite: element.employerWebsite,
              })
              let tempPreviousEmployeerType = element.employerCategory;
              previousEmployeerType = [
                ...previousEmployeerType,
                tempPreviousEmployeerType,
              ];
              setPreviousEmployeerType(previousEmployeerType);

              let tempPreviousEmployeerFromDate = element.workfrom;
              previousEmployeerFromDate = [
                ...previousEmployeerFromDate,
                tempPreviousEmployeerFromDate,
              ];
              setPreviousEmployeerFromDate(previousEmployeerFromDate); //

              let tempPreviousEmployeerTillDate = element.worktill;
              previousEmployeerTillDate = [
                ...previousEmployeerTillDate,
                tempPreviousEmployeerTillDate,
              ];
              setPreviousEmployeerTillDate(previousEmployeerTillDate); //

              let tempPreviousOfficialEmail = element.officialEmail;
              prevOfficialEmailId = [
                ...prevOfficialEmailId,
                tempPreviousOfficialEmail,
              ];

              let count = 0;
              if (tempPreviousEmployeerTillDate) {
                if (
                  tempPreviousEmployeerTillDate &&
                  tempPreviousEmployeerFromDate
                ) {
                  let days = getdaysDifference(
                    new Date(tempPreviousEmployeerFromDate),
                    new Date(tempPreviousEmployeerTillDate)
                  );
                  count = count + days;
                }
                let totalAppliedDays = count + workedSinceDays;
                if (totalAppliedDays < days) {
                  if (
                    repeatCount.length < 2
                  ) {
                    setRepeatCount((prevRepeatCount) => [
                      ...prevRepeatCount,
                      "dummy",
                    ]);

                    setPreviousCount(prevCount + 1);
                    setWorkedFromCounter(workedFromCounter + 1);
                    setWorkedTillCounter(workedTillCounter + 1);
                  }
                }
              }
            }
            if (element.employerType == "Previous Employer 2") {
              const newElement = JSON.parse(JSON.stringify(element)); // Deep copy
              setPreviousEmployerList((prevList) => [...prevList, newElement]);
              let tempPreviousEmployer = element.companyName;
              previousEmployer = [...previousEmployer, tempPreviousEmployer];
              setPrev2Address(element.address);
              if (element.address) {
                if (element.address.city || element.address.state || element.address.line1 || element.address.line2) {
                  setPrev2AddressAdded(true);
                }
              }
              setPrev2Info({
                accNo: element.accNo,
                designation: element.designation,
                employerWebsite: element.employerWebsite,
              })
              let tempPreviousEmployeerType = element.employerCategory;
              previousEmployeerType = [
                ...previousEmployeerType,
                tempPreviousEmployeerType,
              ];
              setPreviousEmployeerType(previousEmployeerType);

              let tempPreviousEmployeerFromDate = element.workfrom;
              previousEmployeerFromDate = [
                ...previousEmployeerFromDate,
                tempPreviousEmployeerFromDate,
              ];
              setPreviousEmployeerFromDate(previousEmployeerFromDate); //

              let tempPreviousEmployeerTillDate = element.worktill;
              previousEmployeerTillDate = [
                ...previousEmployeerTillDate,
                tempPreviousEmployeerTillDate,
              ];
              setPreviousEmployeerTillDate(previousEmployeerTillDate); //

              let tempPreviousOfficialEmail = element.officialEmail;
              prevOfficialEmailId = [
                ...prevOfficialEmailId,
                tempPreviousOfficialEmail,
              ];
            }
          }
        }

        let previousSalaryAccounts = [];
        let salaryAccountBankVar = '';
        if (res) {
          if (res.employerDetails) {
            if (res.employerDetails.length > 0) {
              for (let index = 0; index < res.employerDetails.length; index++) {
                const element = res.employerDetails[index];
                if (element.employerType == "Previous Employer 2" || element.employerType == "Previous Employer 1")
                  previousSalaryAccounts.push(element.bankName);
                if (element.employerType == "Current Employer")
                  salaryAccountBankVar = element.bankName;
              }
            }
          }
        }


        let obj = {
          qualification: res.qualification,
          workExperience: res.workExperience,
          monthlyIncome:res.monthlyIncome,
          monthlyObligation:res.monthlyObligation,
          currentEmployer: currentEmployeerName,
          employerType: employerCategory,
          employerCategory: employerCategory,
          workingSince: workingSince,
          accNo: accNo,
          employerWebsite: employerWebsite,
          designation: designation,
          salaryAccountBank: salaryAccountBankVar,
          officialEmailID: officialEmailID,
          previousEmployer: previousEmployer,
          previousEmployerType: previousEmployeerType,
          workedFrom: [],
          workedTill: [],
          document: "",
          previousSalaryAccountBank: previousSalaryAccounts,
          previousOfficialEmailID: prevOfficialEmailId,
        };
        setFormData(obj);
      }

    }
    fetchBankNames();
  }, [res]);

  useEffect(() => {
    if (!formData.currentEmployer) {
      if (res) {
        if (res.employerDetails) {
          if (res.employerDetails.length == 1) {
            setRepeatCount([])
          } else if (res.employerDetails.length == 2) {
            setRepeatCount(["dummy"])
          } else {
            setRepeatCount(["dummy", "dummy"])
          }
        }
      }
    }
  }, [res]);

  console.log(repeatCount);
  const employerClick = (employerId) => {
    let employerInfo = res.employerDetails[employerId];
    console.log(employerInfo);
    setSelectedEmployeerNames((prevNames) => [
      ...prevNames,
      employerInfo.companyName,
    ]);
    let currentDays = getdaysDifference(
      new Date(employerInfo.workfrom),
      new Date()
    );
    setWorkedSinceDays(currentDays);
    if (currentDays < days) {
      setRepeatCount([...repeatCount, "dummy"]);
      setPreviousCount(prevCount + 1);
      setWorkedFromCounter(workedFromCounter + 1);
      setWorkedTillCounter(workedTillCounter + 1);
    }
    let obj = {
      qualification: res.qualification,
      workExperience: formData.workExperience,
      monthlyIncome:res.monthlyIncome,
      monthlyObligation:res.monthlyObligation,
      currentEmployer: employerInfo.companyName,
      employerType: employerInfo.employerCategory,
      employerCategory: employerInfo.employerCategory,
      workingSince: employerInfo.workfrom,
      salaryAccountBank: "",
      officialEmailID: employerInfo.officialEmail,
      previousEmployer: [],
      previousEmployerType: [],
      workedFrom: [],
      workedTill: [],
      document: employerInfo.document,
      previousSalaryAccountBank: [],
      previousOfficialEmailID: [],
      emp_source: employerInfo.emp_source,
    };
    setFormData(obj);
    setSelectedEmployer(employerInfo);
  };
  const [previousEmployeerType, setPreviousEmployeerType] = React.useState([]);
  const [previousEmployeerFromDate, setPreviousEmployeerFromDate] =
    React.useState([]);
  const [previousEmployeerTillDate, setPreviousEmployeerTillDate] =
    React.useState([]);
  const [previousEmployerList, setPreviousEmployerList] = React.useState([]);
  const previoudEmployerClick = (employerId) => {
    let employerInfo = res.employerDetails[employerId];
    setSelectedEmployeerNames((prevNames) => [
      ...prevNames,
      employerInfo.companyName,
    ]);

    const updatedPreviousEmployers = [...formData.previousEmployer];
    updatedPreviousEmployers[prevCount] = employerInfo.companyName;
    let tempPreviousEmployeerTypeArray = [...previousEmployeerType];
    tempPreviousEmployeerTypeArray[employerId - 1] =
      employerInfo.employerCategory;

    let tempPreviousEmployeerFromDate = [...previousEmployeerFromDate];
    tempPreviousEmployeerFromDate[employerId - 1] = employerInfo.workfrom;

    let tempPreviousEmployeerTillDate = [...previousEmployeerTillDate];
    tempPreviousEmployeerTillDate[employerId - 1] = employerInfo.worktill;

    setFormData({
      ...formData,
      previousEmployer: updatedPreviousEmployers,
      previousEmployerType: tempPreviousEmployeerTypeArray,
    });
    setPreviousEmployeerType(tempPreviousEmployeerTypeArray);
    setPreviousEmployeerFromDate(tempPreviousEmployeerFromDate);
    setPreviousEmployeerTillDate(tempPreviousEmployeerTillDate);
    setPreviousEmployerList([...previousEmployerList, employerInfo]);
    setSelectedPreviuosEmployer(employerInfo);
  };
  const previoudEmployerClickNew = (employerName) => {
    setFormData({
      ...formData,
      previousEmployer: employerName,
    });
    setSelectedPreviuosEmployer({
      companyName: { employerName },
      empUUID: "",
      workfrom: "",
      worktill: "",
      employerType: "",
      employerCategory: "",
      officialEmail: "",
      bankName: "",
      emp_source: "",
    });
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  //NEW CHANGES
  const [isAddressAdded, setAddressAdded] = React.useState(false);
  const [isEmailAdded, setEmailAdded] = React.useState(false);
  const [isPrev1AddressAdded, setPrev1AddressAdded] = React.useState(false);
  const [isPrev2AddressAdded, setPrev2AddressAdded] = React.useState(false);
  const [currentAddress, setCurrentAddress] = React.useState({
    'line1': '',
    'line2': '',
    'pincode': '',
    'city': '',
    'state': ''
  });

  const [prev1Address, setPrev1Address] = React.useState({
    'line1': '',
    'line2': '',
    'pincode': '',
    'city': '',
    'state': ''
  });

  const [prev2Address, setPrev2Address] = React.useState({
    'line1': '',
    'line2': '',
    'pincode': '',
    'city': '',
    'state': ''
  });
  const [pincodeError, setPincodeError] = React.useState('');
  const handleAddressChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == 'pincode' && value == '000000') {
      setPincodeError('Please enter a valid 6-digit pincode')
    }
    setCurrentAddress(prevAddress => ({
      ...prevAddress,
      [name]: value
    }));
    checkFormFilled();
  }


  const handleContinue = async () => {
    const employerDetails = [];
    let employeerNameList = [];
    if (res.employerDetails && res.employerDetails.length) {
      for (let index = 0; index < res.employerDetails.length; index++) {
        const element = res.employerDetails[index];
        employeerNameList.push(element.companyName);
      }
    }
    let temp = res.employerDetails;
    let empSourceList = [];
    if (temp) {
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        let type = element.employerType;
        let value = element.emp_source;
        let obj = { [type]: value };
        empSourceList.push(obj);
      }
    }
    console.log(formData);
    const currentEmployment = {
      companyName: formData.currentEmployer,
      empUUID: employeerNameList.includes(formData.currentEmployer)
        ? selectedEmployer.empUUID
        : "",
      workfrom: formData.workingSince,
      worktill: formattedDate,
      employerType: "Current Employer",
      employerCategory: formData.employerCategory,
      officialEmail: formData.officialEmailID,
      bankName: formData.salaryAccountBank,
      document: selectedEmployer.document,
      emp_source: "",
      accNo: formData.accNo,
      designation: formData.designation,
      employerWebsite: formData.employerWebsite,
      address: currentAddress
    };
    if (empSourceList) {
      const currentEmployerSource = empSourceList.find(item => "Current Employer" in item);
      if (currentEmployerSource) {
        if (currentEmployerSource["Current Employer"]) {
          currentEmployment.emp_source = currentEmployerSource["Current Employer"];
        } else {
          currentEmployment.emp_source = "USER"
        }
      } else {
        if (formData.emp_source) {
          currentEmployment.emp_source = formData.emp_source
        } else {
          currentEmployment.emp_source = "USER"
        }
      }


      employerDetails.push(currentEmployment);
      console.log(currentEmployment);
    }



    repeatCount.forEach((item, index) => {
      let previousEmployeerId;

      if (employeerNameList.includes(formData.previousEmployer[index])) {
        let temp = formData.previousEmployer[index];
        for (let j = 0; j < previousEmployerList.length; j++) {
          const ele = previousEmployerList[j];
          if (ele.companyName == temp) {
            previousEmployeerId = ele.empUUID;
          }
        }
      }
      console.log(previousEmployerList);
      const previousEmployment = {
        companyName: formData.previousEmployer[index],
        empUUID: employeerNameList.includes(formData.previousEmployer[index])
          ? previousEmployeerId
          : "",
        workfrom: previousEmployeerFromDate[index],
        worktill: previousEmployeerTillDate[index],
        employerType: `Previous Employer ${index + 1}`,
        employerCategory: previousEmployeerType[index],
        officialEmail: formData.previousOfficialEmailID[index],
        bankName: formData.previousSalaryAccountBank[index],
        document:
          previousEmployerList &&
          previousEmployerList[index] &&
          previousEmployerList[index].document,
        emp_source: "",

      };

      if (index === 0) {
        const previousEmployerSource = empSourceList.find(item => item.hasOwnProperty("Previous Employer 1"));
        console.log(previousEmployerSource);
        if (previousEmployerSource) {
          if (previousEmployerSource["Previous Employer 1"]) {
            previousEmployment.accNo = prev1Info ? prev1Info.accNo : null;
            previousEmployment.designation = prev1Info ? prev1Info.designation : null;
            previousEmployment.employerWebsite = prev1Info ? prev1Info.employerWebsite : null;
            previousEmployment.address = prev1Address || null;
            previousEmployment.emp_source = previousEmployerSource["Previous Employer 1"];
          } else {
            previousEmployment.emp_source = "USER";
          }
        } else {
          if (previousEmployerList[index] && previousEmployerList[index].emp_source) {
            previousEmployment.emp_source = previousEmployerList[index].emp_source;
          } else {
            previousEmployment.emp_source = "USER";
          }
        }
      }

      if (index == 1) {
        const previousEmployerSource = empSourceList.find(item => "Previous Employer 2" in item);

        if (previousEmployerSource) {
          if (previousEmployerSource["Previous Employer 2"]) {
            previousEmployment.accNo = prev2Info && prev2Info.accNo;
            previousEmployment.designation = prev2Info && prev2Info.designation;
            previousEmployment.employerWebsite = prev2Info && prev2Info.employerWebsite;
            previousEmployment.address = prev2Address;
            previousEmployment.emp_source = previousEmployerSource["Previous Employer 2"];
          } else {
            previousEmployment.emp_source = "USER"
          }
        } else {
          previousEmployment.emp_source = "USER"
        }
      }

      employerDetails.push(previousEmployment);
    });

    // Construct the final API payload
    const apiPayload = {
      qualification: formData.qualification,
      monthlyIncome:formData.monthlyIncome,
      monthlyObligation:formData.monthlyObligation,
      workExperience: parseInt(formData.workExperience, 10),
      employerDetails,
      acceptedEmployerTimePeriodInMonths:
        res && res.acceptedEmployerTimePeriodInMonths, // Update this based on your data
    };

    try {
      const response = await propertyLoanstoreEmployeeHistoryAPI(apiPayload);
      if (response.status === 200 && response?.data?.businessStatusCode === 2) {
        const response2 = await accountAggregatorAPI();
        if (
          (response2?.data?.businessStatusCode === 2 &&
            response2?.data?.businessStatusSubCode === 2.2)
        ) {
          changeApplicantStatus(response2?.data?.nextPage);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error?.response?.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error storing employee history:", error);
    }
  };

  const [days, setDays] = React.useState(
    getTotalDaysInLast12Months(res && res.acceptedEmployerTimePeriodInMonths)
  );
  // const [days, setDays] = React.useState(356);
  useEffect(() => {
    if (res) {
      if (res.acceptedEmployerTimePeriodInMonths)
        setDays(
          getTotalDaysInLast12Months(res.acceptedEmployerTimePeriodInMonths)
        );
    }
  }, [res]);

  const [workedFromCounter, setWorkedFromCounter] = React.useState(1);
  const [workedTillCounter, setWorkedTillCounter] = React.useState(1);
  const [workedSinceDays, setWorkedSinceDays] = React.useState(0);

  const getdaysDifference = (from, to) => {
    let timeDifference = to.getTime() - from.getTime();
    let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const getWorkingSince = (e) => {
    let currentDays = getdaysDifference(new Date(e.target.value), new Date());
    setWorkedSinceDays(currentDays);

    // Check if the "Working Since" date is greater than the "Worked Till" date of the previous employer
    if (currentDays < days && repeatCount.length < 2) {
      if (
        (previousEmployeerTillDate[prevCount - 1] &&
          new Date(e.target.value) <=
          new Date(previousEmployeerTillDate[prevCount - 1])) ||
        (formData.workedTill[prevCount - 1] &&
          new Date(e.target.value) <=
          new Date(formData.workedTill[prevCount - 1]))
      ) {
        console.log('Working Since should be greater than Worked Till of previous employer.');
        // You can handle this situation, e.g., show an error message.
        return;
      }

      setRepeatCount([...repeatCount, "dummy"]);
      setPreviousCount(prevCount + 1);
      setWorkedFromCounter(workedFromCounter + 1);
      setWorkedTillCounter(workedTillCounter + 1);
    }
  };


  const getWorkTill = (e, myindex) => {
    let count = 0;
    if (e) {
      for (let index = workedFromCounter; index >= 1; index--) {
        let workedFromTag = document.getElementsByClassName(
          "workedFrom" + index
        )[0];
        let workedFromInput;
        if (workedFromTag) {
          workedFromInput = workedFromTag.getElementsByTagName("input")[0];
        }

        let workedTillTag = document.getElementsByClassName(
          "workedTill" + myindex
        )[0];
        let workedTillInput;
        if (workedTillTag) {
          workedTillInput = workedTillTag.getElementsByTagName("input")[0];
        }

        if (workedTillInput && workedFromInput) {
          let days = getdaysDifference(

            new Date(workedFromInput.value),
            new Date(e)
          );
          count = count + days;
        }
      }
      let totalAppliedDays = count + workedSinceDays;
      if (totalAppliedDays < days) {
        //TODO create given block of code dynamically
        if (repeatCount.length < 2) {
          setRepeatCount([...repeatCount, "dummy"]);
          setPreviousCount(prevCount + 1);
          setWorkedFromCounter(workedFromCounter + 1);
          setWorkedTillCounter(workedTillCounter + 1);
        }
      }
    }
  };

  //Code added for editable text
  const [customText, setCustomText] = useState("");

  const handleCustomTextChange = (event) => {
    setFormData({ ...formData, ["currentEmployer"]: event.target.value });
    setCustomText(event.target.value);
  };

  const handleTextFieldClick = (event) => {
    // Stop event propagation to prevent closing the dropdown

    //setFormData( { ...formData, [ "currentEmployer" ]: "" } );

    event.stopPropagation();
  };

  const [employerCategoryValues, setemployerCategoryValues] = useState([]);
  useEffect(() => {
    fetchEmployerCategoryValues();
  }, [])
  const fetchEmployerCategoryValues = async () => {
    try {
      const apiPayload = {
        "type": "EMPLOYER_CATEGORY",
        "key": "EMPLOYER_CATEGORY",
        "source": "json_value"
      }

      const employerCatagoryResponse = await propertyLoanFetchDataAPI(apiPayload);
      setemployerCategoryValues(employerCatagoryResponse?.data?.[0]?.values);

    } catch (error) {
      if (error?.response?.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching address vintage:", error);
    }
  };
  const employeerCategoryList = employerCategoryValues?.map((qualification) => {
    return qualification;
  });

  const [WorkExperience, setWorkExperience] = useState([]);
  const fetchWorkExperience = async () => {

    try {
      const apiPayload = {
        type: "TOTAL_WORK_EXPERIENCE",
        key: "TOTAL_WORK_EXPERIENCE",
        source: "json_value",
      };
      const workExperienceRes = await propertyLoanFetchDataAPI(apiPayload);
      setWorkExperience(workExperienceRes?.data[0].values);

    } catch (error) {
      if (error?.response?.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching address vintage:", error);
    }
  };
  const validExperiences = WorkExperience?.map((experience) => {
    return experience;
  });

  const [bankNames, setBankNames] = React.useState([]);
  const fetchBankNames = async () => {

    try {
      const apiPayload = {
        type: "DIGITAP_BANK_STATEMENT_INSTITUTION_ID",
        key: "",
        source: "key",
      };
      const banknameRes = await propertyLoanFetchDataAPI(apiPayload);
      setBankNames(banknameRes?.data);


    } catch (error) {
      if (error?.response?.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching address vintage:", error);
    }
  };
  useEffect(() => {
    fetchBankNames();
  }, [])
  useEffect(() => {
    fetchWorkExperience();
  }, [])

  const handleUploadDocument = () => {
    setPopupOpen1(true);
  };
  const handlePopupClose1 = (selectedFileNames) => {
    console.log(selectedFileNames);
    //setFileNames1( selectedFileNames );
    if (selectedFileNames.length > 0) {
      let doc = selectedFileNames;
      setSelectedEmployer((prevSelectedEmployer) => {
        if (prevSelectedEmployer.document) {
          return {
            ...prevSelectedEmployer,
            document: [...prevSelectedEmployer.document, ...doc],
          };
        } else {
          return { ...prevSelectedEmployer, document: doc };
        }
      });
    }
    setIsIDUploaded(true);
    setPopupOpen1(false);
    console.log(selectedEmployer);
  };
  const updatePrevDoc = (index, selecteddocuments) => {
    console.log(selecteddocuments);
    setPreviousEmployerList((prevList) => {
      return prevList?.map((employer, i) => {
        if (i === index) {
          if (!employer.document) {
            employer.document = [];
          }
          return {
            ...employer,
            document: [...employer.document, ...selecteddocuments],
          };
        }

        return employer; // Keep other employers unchanged
      });
    });
  };
  //Validation
  const [isAllFieldsFilled, setAllFieldsFilled] = useState(false);

  const checkAllFieldsFilled = () => {
    const requiredFields = [
      "qualification",
      "workExperience",
      "currentEmployer",
      "monthlyIncome",
      "monthlyObligation",
      "employerCategory",
      "workingSince",
      "salaryAccountBank",
      "accNo",
      "designation"
    ];
    const isEmailValid = isValidEmail(formData.officialEmailID || "");
    const areFieldsFilled = requiredFields.every((field) => {
      return formData[field] !== undefined && formData[field] !== "";
    });



    let workingSinceForCurrent = formData.workingSince;
    let totalDays = 0;
    if (workingSinceForCurrent) {
      let dateParts = workingSinceForCurrent.split("-");
      let year = parseInt(dateParts[0]);
      let month = parseInt(dateParts[1]) - 1; // Months are zero-based, so subtract 1
      let day = parseInt(dateParts[2]);

      let workingSinceDate = new Date(year, month, day);
      let today = new Date();


      if (workingSinceDate instanceof Date && today instanceof Date) {
        let diffMilliseconds = today.getTime() - workingSinceDate.getTime();
        totalDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
      }
    }
    for (let index = 0; index < 2; index++) {
      const element = previousEmployerList[index];
      if (previousEmployeerFromDate[index] && previousEmployeerTillDate[index]) {
        let workFrom = previousEmployeerFromDate[index];
        let workTo = previousEmployeerTillDate[index];

        // Parse the start date
        let datePartsStart = workFrom.split("-");
        let yearStart = parseInt(datePartsStart[0]);
        let monthStart = parseInt(datePartsStart[1]) - 1; // Months are zero-based, so subtract 1
        let dayStart = parseInt(datePartsStart[2]);
        let workingFromDate = new Date(yearStart, monthStart, dayStart);

        // Parse the end date
        let datePartsEnd = workTo.split("-");
        let yearEnd = parseInt(datePartsEnd[0]);
        let monthEnd = parseInt(datePartsEnd[1]) - 1; // Months are zero-based, so subtract 1
        let dayEnd = parseInt(datePartsEnd[2]);
        let workingToDate = new Date(yearEnd, monthEnd, dayEnd);

        if (workingFromDate instanceof Date && workingToDate instanceof Date) {
          let diffMilliseconds = workingToDate.getTime() - workingFromDate.getTime();
          let temp = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
          totalDays = temp + totalDays;
        }
      }
    }

    let daysCompleted = false;
    let acceptedEmployerTimePeriodInMonths = res && res.acceptedEmployerTimePeriodInMonths;
    let acceptedEmployerTimePeriodInDays = getTotalDaysInLast12Months(acceptedEmployerTimePeriodInMonths);
    console.log(totalDays);
    console.log(acceptedEmployerTimePeriodInDays);
    if (totalDays >= acceptedEmployerTimePeriodInDays) {
      daysCompleted = true;
    }
    setAllFieldsFilled(
      areFieldsFilled && (formData.officialEmailID ? isEmailValid : true) && daysCompleted

    );
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const [isEmployeerFormValid, setEmployeerFormValid] = useState(false);
  useEffect(() => {
    checkAllFieldsFilled();
    checkFormFilled();
  }, [formData, isAllFieldsFilled, isEmployeerFormValid, currentAddress]);

  const isValidateEmployeerForm = (isValid) => {
    setEmployeerFormValid(isValid);
  };

  const removeExistingDoc = (documentType) => {
    let updatedDocuments = [...selectedEmployer.document];

    // Filter out the document with the given documentType
    updatedDocuments = updatedDocuments.filter(doc => doc.documentType !== documentType);

    // Update the state with the updated array
    setSelectedEmployer(prevState => ({
      ...prevState,
      document: updatedDocuments
    }));

  }
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [isOpenEmailDialog, setOpenEmailDialog] = useState(false);

  const openAddrDialog = () => {
    setOpenAddressDialog(true);
    setPincodeError('')
  }
  
  const closeAddrDialog = () => {
    if (isAddressAdded) {
      setOpenAddressDialog(false);
    } else {
      setOpenAddressDialog(false);
      setCurrentAddress({
        'line1': '',
        'line2': '',
        'pincode': '',
        'city': '',
        'state': ''
      })
    }
  }

  const closeEmailDialog = () => {
    setOpenEmailDialog(false);
    if (!isEmailAdded) {
      setFormData({
        ...formData,
        officialEmail: '',
      })
    }
  }

  const [prev1Info, setPrev1Info] = React.useState({
    accNo: '',
    designation: '',
    employerWebsite: '',
  })

  const [prev2Info, setPrev2Info] = React.useState({
    accNo: '',
    designation: '',
    employerWebsite: '',
  })

  const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
  const openOTPDialog = async () => {
    const requestBody = { id: formData.officialEmail }
    try {
      const response = await propertyLoanEmailVerification(requestBody);
      if (response.data.httpResponseCode === 200) {
        setOpenOTPDialog(true);
      } else {
        console.log("Unexpected response status:", response?.status);
      }

    } catch (error) {
      if (error.response && error?.response?.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error during OTP verification:", error);
    }
  }


  const handleVerifyClick = async (e) => {
    e.preventDefault();

    setOpenOTPDialog(false);
    setOpenEmailDialog(false);
    const requestBody = {
      "otp": otp.join(""), // Join the otp array into a string
    };

    if (otp.join("") === "") { // Check if otp is empty
      setIsInvalidOtp(true);
      inputRefs.current[0].current.focus();
      return;
    }

    try {
      const response = await propertyLoanEmailOtpValidation(requestBody);
      if (response.data.httpResponseCode === 200) {
        setOpenOTPDialog(false);
        setEmailAdded(true);
      } else {
        console.log("Unexpected response status:", response?.status);
        setEmailAdded(false);
      }

    } catch (error) {
      if (error.response && error?.response?.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error during OTP verification:", error);
    }
  };

  const closeOTPDialog = () => {
    setOpenOTPDialog(false);
  }
  const [isInvalidOtp, setIsInvalidOtp] = useState(true);
  const inputRefs = useRef([]);
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [])
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Updated to have six elements in the array

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < otp.length - 1 && value !== '') {
      // Move focus to the next input if not on the last input and a digit is entered
      inputRefs.current[index + 1].focus();
    }
    const isAllFieldsFilled = newOtp.every((otpValue) => otpValue !== "");
    // Enable or disable the "Continue" button based on the OTP fields status
    setIsInvalidOtp(!isAllFieldsFilled);
  };
  const otpString = otp.join('');
  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      // Move focus to the previous input on Backspace
      inputRefs.current[index - 1].focus();
    }
  };
  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };
  const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
  const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
  const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const checkFormFilled = () => {
    if (!currentAddress) return;

    const { line1, line2, pincode, city, state } = currentAddress;

    const isLine1Filled = line1 && line1.trim() !== '' && /^[a-zA-Z0-9,\s]+$/.test(line1);
    const isLine2Filled = line2 && line2.trim() !== '' && /^[a-zA-Z0-9,\s]+$/.test(line2);
    const isPincodeFilled = pincode && pincode.trim() !== '' && /^\d{6}$/.test(pincode); // Assuming pincode is 6 digits
    const isCityFilled = city && city.trim() !== '' && /^[a-zA-Z\s]+$/.test(city);
    const isStateFilled = state && state.trim() !== '' && /^[a-zA-Z\s]+$/.test(state);

    let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
    if (pincode == '000000') {
      isPincodeValidated = false;
    }
    if (isPincodeValidated) {
      setPincodeError('');
    }
    const isCityValidated = isCityFilled && isCityValid(city);
    const isStateValidated = isStateFilled && isStateValid(state);

    if (
      isLine1Filled &&
      isLine2Filled &&
      isPincodeFilled &&
      isCityFilled &&
      isStateFilled &&
      isPincodeValidated &&
      isCityValidated &&
      isStateValidated
    ) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }

  };


  return (
    <>

      <div className="row mt-4">
        <div className="col-12">
          <h6>Qualification, Experience & Income Details</h6>
          <p className="small-heading kyc-form-text-size">
            Share your academic background and industry experience
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <ArthaFormControl fullWidth>
            <InputLabel id="qualification-label">
              Qualification
            </InputLabel>
            <Select
              labelId="qualification-label"
              id="qualification"
              name="qualification"
              value={formData.qualification}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  qualification: e.target.value,
                })
              }
            >
              <MenuItem value={res?.qualification}>
                {res?.qualification}
              </MenuItem>
              {res?.qualification !== "Graduate" && (
                <MenuItem value="Graduate">Graduate</MenuItem>
              )}
              {res?.qualification !== "Post Graduate" && (
                <MenuItem value="Post Graduate">Post Graduate</MenuItem>
              )}
              {res?.qualification !== "other" && (
                <MenuItem value="other">Other</MenuItem>
              )}
            </Select>
          </ArthaFormControl>
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0">
          <ArthaFormControl fullWidth>
            <InputLabel id="totalExperience">
              Total Work Experience
            </InputLabel>
            <Select
              labelId="totalExperience"
              id="experience"
              name="workExperience"
              value={formData.workExperience}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  workExperience: e.target.value,
                })
              }
            >
              {validExperiences?.map((item, index) => {
                const numericValue = item.replace(/\D/g, '');
                return (
                  <MenuItem key={index} value={numericValue}>
                    {item}
                  </MenuItem>
                )
              })}

            </Select>
          </ArthaFormControl>
        </div>
      </div>

      <div className="row mt-4">
                            <div className="col-md-6">
                                <ArthaTextField
                                    id="outlined-basic"
                                    name="monthlyIncome"
                                    label="Monthly Income (₹)"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.monthlyIncome}
                                    // onChange={handleChange2}
                                    onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          monthlyIncome : e.target.value,
                                        })
                                      }
                                    InputProps={{
                                        style: {
                                            height: '50px' // Adjust the height as needed
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            padding: '18px 14px' // Adjust padding to center the text vertically
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <ArthaTextField
                                    id="outlined-basic"
                                    name="monthlyObligation"
                                    label="Monthly Obligations (₹)"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.monthlyObligation}
                                    onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          monthlyObligation : e.target.value,
                                        })
                                      }
                                    InputProps={{
                                        style: {
                                            height: '50px' // Adjust the height as needed
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            padding: '18px 14px' // Adjust padding to center the text vertically
                                        }
                                    }}
                                />
                            </div>

                        </div>
      <div className="row mt-4 mt-md-5">
        <div className="col-12">
          <p className="small-heading kyc-form-text-size">
            Please provide employment details for the past 1 year.
          </p>
          <h6>Employement Details</h6>
          {res?.employerDetails && res?.employerDetails.length > 0 && (
            <p className="small-heading kyc-form-text-size">
              Enter Details of current Employer
            </p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          {res?.employerDetails ? (
            <ArthaFormControl fullWidth >
              <InputLabel id="currentEmployer-label">
                Current Employer Name
              </InputLabel>
              <Select

                labelId="currentEmployer-label"
                id="currentEmployer"
                name="currentEmployer"
                displayEmpty
                renderValue={(value) =>
                  value === "editable" ? customText : value
                }
                value={formData.currentEmployer}
              >
                <MenuItem value="">Current Employer Name</MenuItem>
                {res?.employerDetails &&
                  res?.employerDetails?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.companyName}
                        onClick={() => {
                          employerClick(index);
                        }}
                      >
                        {item.companyName}
                      </MenuItem>
                    );
                  })}

                <MenuItem value="editable">
                  <TextField
                    placeholder="add new employeer"
                    value={customText}
                    onChange={handleCustomTextChange}
                    onClick={handleTextFieldClick}
                    className="employeer-field"
                    style={{ width: "100%" }}
                  />
                </MenuItem>
              </Select>
            </ArthaFormControl>
          ) : (
            <ArthaFormControl fullWidth>
              <InputLabel id="currentEmployer-label">
                Current Employer Name
              </InputLabel>
              <Select
                labelId="currentEmployer-label"
                id="currentEmployer"
                name="currentEmployer"
                displayEmpty
                renderValue={(value) =>
                  value === "editable" ? customText : value
                }
                value={formData.currentEmployer}
              >
                <MenuItem value="">Current Employer Name</MenuItem>

                <MenuItem value="editable">
                  <TextField
                    placeholder="add new employeer"
                    value={customText}
                    onChange={handleCustomTextChange}
                    onClick={handleTextFieldClick}
                    className="employeer-field"
                    style={{ width: "100%", height: "20px" }}
                  />
                </MenuItem>
              </Select>
            </ArthaFormControl>
          )}
        </div>
        <div className="col-12 col-md-6  col-lg-6 mt-3 mt-md-0">
          <ArthaFormControl fullWidth>
            <InputLabel id="currentEmployerType">
              Current Employer Type
            </InputLabel>
            <Select
              labelId="currentEmployerType"
              id="employerType"
              name="employerCategory"
              value={formData.employerCategory}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  employerCategory: e.target.value,
                })
              }
            >
              {employerCategoryValues &&
                employerCategoryValues?.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </ArthaFormControl>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
          <span className="msg" >Employer Address</span><br />

          <>
            <div className="row">

              {isAddressAdded ? (
                <>
                  <div className="col-8">
                    <FaExclamationCircle className="badge-icon" color="green" style={{ marginTop: "0" }} />
                    <span className="mt-2 upload-lable"> Address Added! </span>
                  </div>
                  <div className="col-4">
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none"
                      onClick={openAddrDialog}
                      style={{ fontSize: '11px !important' }}
                    >
                      Edit
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-7">
                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                    <span className=" upload-lable" style={{ marginTop: "10px !important" }}> Not added </span>
                  </div>
                  <div className="col-5">
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none "
                      onClick={openAddrDialog}
                      style={{ fontSize: '11px !important' }}
                    >
                      Add Address
                    </Button>
                  </div>
                </>
              )}

            </div>
          </>
        </div>
        <>
          <Dialog
            open={openAddressDialog}

            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="modal row">
              <div className="modal-content col-md-6">
                {!isAddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <ArthaTextField
                      name="line1"
                      fullWidth
                      value={currentAddress && currentAddress.line1}
                      onChange={(e) => { handleAddressChange(e) }}
                      label="Address Line 1"

                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <ArthaTextField
                      name="line2"
                      onChange={(e) => { handleAddressChange(e) }}
                      value={currentAddress && currentAddress.line2}
                      fullWidth
                      label="Address Line 2"
                    />
                  </div>
                  <div className="col-md-6 mt-5 mt-md-3">
                    <ArthaTextField
                      name="pincode"
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/\D/g, '').slice(0, 6);
                        handleAddressChange({ target: { name: 'pincode', value: inputValue } });
                      }}
                      value={currentAddress && currentAddress.pincode}
                      label="Pincode"
                      fullWidth
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>{pincodeError}</span>

                  </div>
                  <div className="col-md-6 mt-3 mt-md-3">
                    <ArthaTextField
                      name="city"
                      label="City"
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                        handleAddressChange({ target: { name: 'city', value: inputValue } });
                      }}
                      value={currentAddress && currentAddress.city}
                      fullWidth

                    />
                  </div>
                  <div className="col-md-6 mt-3 mt-md-3">
                    <ArthaTextField
                      name="state"
                      label="State"
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                        handleAddressChange({ target: { name: 'state', value: inputValue } });
                      }}
                      value={currentAddress && currentAddress.state}
                      fullWidth
                    />
                  </div>
                </div>

                <div className="row mt-4 mx-auto">
                  <div className="col-6 d-flex justify-content-end mt-4">
                    <ArthaClearButton variant="contained" onClick={closeAddrDialog}>
                      Cancel
                    </ArthaClearButton>
                  </div>
                  <div className="col-6 d-flex justify-content-start mt-4">
                    <ArthaContinueButton
                      variant="contained"
                      disabled={!isFormFilled}
                      onClick={() => { setOpenAddressDialog(false); setAddressAdded(true) }}
                    >
                      Save
                    </ArthaContinueButton>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </>

        <>
          <Dialog
            open={isOpenEmailDialog}

            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="modal row">
              <div className="modal-content col-md-6">
                {!isEmailAdded ? <h6> Add Official Email ID</h6> : <h6>Edit Email</h6>}
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <span>Please enter official email id for your current employer. OTP validation will be needed for confirming the email id.</span>
                  </div>
                  <div className="col-md-12 mt-3">
                    <ArthaTextField
                      name="line2"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          officialEmail: e.target.value,
                        })
                      }}
                      value={formData.officialEmail}
                      fullWidth
                      label="Email ID"
                      helperText={!isEmailValid(formData.officialEmail) && 'Invalid email format'}
                      error={!isEmailValid(formData.officialEmail)}
                    />
                  </div>

                </div>

                <div className="row mt-4 mx-auto">
                  <div className="col-6 d-flex justify-content-end mt-4">
                    <ArthaClearButton variant="contained" onClick={closeEmailDialog}>
                      Cancel
                    </ArthaClearButton>
                  </div>
                  <div className="col-6 d-flex justify-content-start mt-4">
                    <ArthaContinueButton
                      variant="contained"
                      disabled={!emailRegex.test(formData.officialEmail)} // Disable button if email format is invalid

                      onClick={() => { openOTPDialog(); }}
                    >
                      Continue
                    </ArthaContinueButton>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </>

        <>
          <Dialog
            open={isOpenOTPDialog}

            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className="otpBox">
              <div className="AdharOTPForm">
                <div className="AdharOTPHeading mt-3">
                  <h5>Otp Validation</h5>

                  <p className="msg mt-4" style={{ padding: "0", margin: "0" }}>
                    Please Enter OTP Sent to
                  </p>
                  <span className="msg mt-4" style={{ fontSize: "10px", color: "blue", padding: "0", margin: "0" }}>
                    {formData.officialEmail}
                  </span>

                  <div className="Verify-OTP mt-5">
                    <div className="Verify-OTP-section">
                      <div className="otp-inputs">
                        {otp?.map((value, index) => (
                          <input
                            key={index}
                            type="text"
                            className={`otp-input ${isInvalidOtp ? "error" : ""}`}
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(index, e.target.value)}
                            onKeyDown={(e) => handleInputKeyDown(index, e)}
                            ref={(ref) => (inputRefs.current[index] = ref)} // Store the reference to each input field
                          />
                        ))}
                      </div>

                      <br />



                      <h6 className="text-center">
                        Didn't Receive any Code?{" "}
                        <a
                          href="#"
                          style={{ color: "rgb(255, 136, 0)", marginLeft: "5px" }}

                        >
                          <h6 className="mt-3" >Resend Code</h6>
                        </a>
                      </h6>

                    </div>
                    <div className="row mt-5 ">
                      <div className="col-6 d-flex justify-content-end">
                        <ArthaClearButton
                          variant="contained"
                          onClick={handleBack}
                        >
                          Back
                        </ArthaClearButton>
                      </div>

                      <div className="col-6 d-flex justify-content-start">
                        <ArthaContinueButton
                          onClick={handleVerifyClick}
                          variant="contained"
                          disabled={isInvalidOtp}
                        >
                          Continue
                        </ArthaContinueButton>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Dialog>
        </>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
          <ArthaTextField
            type="text"
            name="employerWebsite"
            value={formData.employerWebsite}
            onChange={(e) =>
              setFormData({
                ...formData,
                employerWebsite: e.target.value,
              })
            }
            label="Employer Website (Optional)"
            fullWidth
            InputProps={{
              style: {
                height: '50px' // Adjust the height as needed
              }
            }}
            inputProps={{
              style: {
                maxLength: 80,
                padding: '18px 14px' // Adjust padding to center the text vertically
              }
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
          <ArthaTextField
            type="text"
            name="designation"
            value={formData.designation}
            onChange={(e) =>
              setFormData({
                ...formData,
                designation: e.target.value,
              })
            }
            label="Designation"
            fullWidth
            InputProps={{
              style: {
                height: '50px' // Adjust the height as needed
              }
            }}
            inputProps={{
              style: {
                maxLength: 80,
                padding: '18px 14px' // Adjust padding to center the text vertically
              }
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
          <ArthaTextField
            type="date"
            name="workingSince"
            InputProps={{
              inputProps: {
                max: new Date().toISOString().split("T")[0],
              },
            }}
            value={formData.workingSince}
            label="Working Since"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setFormData({
                ...formData,
                workingSince: e.target.value,
              });
            }}
            onBlur={
              (e) => {
                getWorkingSince(e);
              }
            }
            onSelect={(e) => { }}
            fullWidth
          />
        </div>


        <div className="col-12 col-md-6  col-lg-6 mt-3 mt-md-4">
          <ArthaFormControl fullWidth>
            <InputLabel id="salaryAccount-label">
              Salary Account Bank
            </InputLabel>
            <Select
              labelId="salaryAccount-label"
              id="salaryAccountBank"
              name="salaryAccountBank"
              value={formData.salaryAccountBank}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  salaryAccountBank: e.target.value,
                })
              }
            >
              {bankNames &&
                bankNames?.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </ArthaFormControl>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
          <ArthaTextField
            type="text"
            name="accNo"
            value={formData.accNo}
            onChange={(e) =>
              setFormData({
                ...formData,
                accNo: e.target.value,
              })
            }
            label="Salary Account Number"
            fullWidth
            InputProps={{
              style: {
                height: '50px' // Adjust the height as needed
              }
            }}
            inputProps={{
              style: {
                maxLength: 80,
                padding: '18px 14px' // Adjust padding to center the text vertically
              }
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-3">
          <span className="msg">Official Email Address(Optional)</span><br />
          <div className="row">
            {isEmailAdded ? <>
              <div className="col-8">
                <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px", color: 'green' }} />
                <span
                  className="mt-2 upload-lable"

                >
                  {" "}
                  Email added
                </span>
              </div>
              <div className="col-4">
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none "
                  onClick={() => { setOpenEmailDialog(true); }}
                  style={{ fontSize: '11px !important' }}
                >
                  Edit
                </Button>
              </div>
            </> : <>
              <div className="col-8">
                <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                <span
                  className="mt-2 upload-lable"

                >
                  {" "}
                  Not added
                </span>
              </div>
              <div className="col-4">
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none "
                  onClick={() => { setOpenEmailDialog(true); }}
                  style={{ fontSize: '11px !important' }}
                >
                  Add Email
                </Button>
              </div>
            </>}
          </div>
        </div>

        <div className="col-8 col-md-4 mt-5 mt-md-4 ">
          <p className="msg">ID Card (optional)</p>

          {isIDUploaded || selectedEmployer.document ? (
            <>
              <span
                className="mt-2 upload-lable"
                style={{ position: "relative", top: "-10px" }}
              >
                {" "}
                {selectedEmployer && selectedEmployer.document ? (
                  selectedEmployer.document.map((item) => {
                    return (
                      <p>
                        {" "}
                        <FaCheck className="check-icon" />
                        <span style={{ marginRight: "10px" }}>
                          {item.documentType}
                        </span>
                      </p>
                    );
                  })
                ) : (
                  <>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      {" "}
                      Not Uploaded
                    </span>
                  </>
                )}
              </span>

            </>
          ) : (
            <>
              <FaExclamationCircle className="badge-icon" />
              <span
                className="mt-2 upload-lable"
                style={{ position: "relative", top: "-10px" }}
              >
                {" "}
                Not Uploaded
              </span>
            </>
          )}
        </div>
        <div className="col-4 col-md-2 mt-4">
          <input
            type="file"
            ref={fileInputRefID}
            style={{ display: "none" }}
            onChange={handleFileChangeID}
          />
          <Button
            variant="contained"
            className="upload_btn btn_white shadow-none mt-3"
            onClick={handleUploadDocument}
          >
            Upload
          </Button>
          <UploadDocument
            open={isPopupOpen1}
            existingDocuments={selectedEmployer.document}
            onRemove={removeExistingDoc}
            handleClose={handlePopupClose1}
          />
        </div>
      </div>

      {repeatCount?.map((item, index) => (
        index < 2 && <>
          <div className="row mt-5">
            <div className="col-12">
              <p className="small-heading kyc-form-text-size">
                we need to employer details of last six months. Add
                Details of previous employer
              </p>
            </div>
          </div>

          <EmployeerForm
            key={item}
            updatePrevDoc={updatePrevDoc}
            currentIndex={index}
            previousEmployerList={previousEmployerList}
            setPreviousEmployerList={setPreviousEmployerList}
            previousEmployeerDocuments={previousEmployerList[index]}
            setPreviousEmployeerFromDate={setPreviousEmployeerFromDate}
            setPreviousEmployeerTillDate={setPreviousEmployeerTillDate}
            previousEmployeerType={previousEmployeerType}
            setPreviousEmployeerType={setPreviousEmployeerType}
            previousEmployeerTillDate={previousEmployeerTillDate}
            employeerCategoryList={employeerCategoryList}
            selectedEmployeerNames={selectedEmployeerNames}
            prevCount={index}
            res={res}
            formData={formData}
            previoudEmployerClick={previoudEmployerClick}
            previoudEmployerClickNew={previoudEmployerClickNew}
            setFormData={setFormData}
            workedFromCounter={workedFromCounter}
            workedTillCounter={workedTillCounter}
            getWorkTill={getWorkTill}
            bankNames={bankNames}
            selectedPreviuosEmployer={selectedPreviuosEmployer}
            fileName2={fileName2}
            fileInputRefID2={fileInputRefID2}
            handleFileChangeID2={handleFileChangeID2}
            handleUploadButtonClickID2={handleUploadButtonClickID2}
            previousEmployeerFromDate={previousEmployeerFromDate}
            isValidateEmployeerForm={isValidateEmployeerForm}
            isPrev1AddressAdded={isPrev1AddressAdded}
            isPrev2AddressAdded={isPrev2AddressAdded}
            prev1Address={prev1Address}
            prev2Address={prev2Address}
            setPrev1Address={setPrev1Address}
            setPrev2Address={setPrev2Address}
            setPrev1AddressAdded={setPrev1AddressAdded}
            setPrev2AddressAdded={setPrev2AddressAdded}
            setPrev1Info={setPrev1Info}
            prev1Info={prev1Info}
            setPrev2Info={setPrev2Info}
            prev2Info={prev2Info}
          />
          <p style={{ marginTop: '30px' }}></p>
        </>
      ))}
      <div className="row mt-4">
        <div className="col-6 d-flex justify-content-end">
          <ArthaClearButton variant="contained" onClick={handleBack}>
            Back
          </ArthaClearButton>
        </div>
        <div className="col-6 d-flex justify-content-start">
          <ArthaContinueButton
            variant="contained"
            onClick={handleContinue}
            style={{
              color: "white",
              backgroundColor: "rgb(255, 98, 0)",

              opacity:

                (repeatCount.length > 0
                  ? (!isAllFieldsFilled || !isEmployeerFormValid)
                  : !isAllFieldsFilled) ? 0.39
                  : 1,
            }}
            disabled={
              repeatCount.length > 0
                ? (!isAllFieldsFilled || !isEmployeerFormValid)
                : !isAllFieldsFilled
            }
          >
            Continue
          </ArthaContinueButton>
        </div>
      </div>
    </>

  );
};

export default AdditionalEmployementDetails;